import React from 'react'
import Img from 'gatsby-image'
import {getFixedGatsbyImage, getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../client-config'

import './figure.module.css'

export default ({node}) => {
  // || !node.asset.metadata
  if (!node.asset) {
    return null
  }

  // Extract dimensions from the image metadata
  // const {width, height} = node.asset.metadata.dimensions
  const maxwidth = node?.maxWidth || null

  if(maxwidth){
    const fixedProps = getFixedGatsbyImage(
      node.asset._ref,
      {width: maxwidth}, // Use the original width; height is calculated automatically
      clientConfig.sanity
    )

    return (
      <>
        <figure style={{maxWidth: `100%`}}>
          <Img fixed={fixedProps} alt={node.alt} />
          <figcaption>{node.caption}</figcaption>
        </figure>
      </>
    )

  } else {
  const fluidProps = getFluidGatsbyImage(node.asset._ref, {maxWidth: 890}, clientConfig.sanity)

  return (
    <>
      <figure style={{maxWidth: '890px'}}>
        <Img fluid={fluidProps} alt={node.alt} />
        <figcaption>{node.caption}</figcaption>
      </figure>
    </>
  )
  }
}
